import React from 'react';
import { SXTypography } from 'scribexUI';
import { Col, Row, Space } from 'componnets';
import member1 from 'assets/images/team/member1.jpg';
import member2 from 'assets/images/team/member2.jpg';
import member3 from 'assets/images/team/member3.jpg';
import member4 from 'assets/images/team/member4.jpg';
import member5 from 'assets/images/team/member5.jpg';
import member6 from 'assets/images/team/member6.jpg';
import member7 from 'assets/images/team/member7.jpg';
import member8 from 'assets/images/team/member8.jpg';
import member9 from 'assets/images/team/member9.jpg';
import { IPersonalCard, PersonalCard } from 'componnets';
import styles from './styles.module.scss';

const {Text} = SXTypography;

const teamMemberData: IPersonalCard[] = [
  {
    id: '1',
    image: member1,
    name: 'TIRUHI IVANYAN',
    position: 'UI/UX Design'
  },
  {
    id: '2',
    image: member5,
    name: 'HOVHANNES MARTIROSYAN',
    position: 'Back End Development'
  },
  {
    id: '3',
    background: '#331DAD',
    specialTitle: `It's all about the people`
  },
  {
    id: '4',
    image: member4,
    name: 'NATALYA TUROVTSEVA',
    position: 'Product and Operations Management'
  },
  {
    id: '5',
    image: member9,
    name: 'ARAM MIRZOYAN',
    position: 'Full Stack Development'
  },
  {
    id: '6',
    image: member3,
    name: 'MARY NAZARETYAN',
    position: 'Back End Development'
  },
  {
    id: '7',
    background: '#15A6FF',
    title: `We're always looking for new talent.`,
    link: 'Join the tribe'
  },
  {
    id: '8',
    image: member2,
    name: 'SILVI DURINYAN',
    position: 'Back End Development'
  },
  {
    id: '9',
    image: member7,
    name: 'ARTAK PILOSYAN',
    position: 'Front End Development'
  },
  {
    id: '10',
    image: member8,
    name: 'TATEVIK HARUTYUNYAN',
    position: 'Quality Assurance'
  },
  {
    id: '11',
    image: member6,
    name: 'RAFAYEL POGHOSYAN',
    position: 'Front End Development'
  }
]
export const Team = React.memo(() => {
  return (
    <Row className={styles.team} gutter={[0, {xl: 10, xs: 16, sm: 16}]}>
      <Col span={24}>
        <Space className={styles.title_container} wrap={false}>
          <Space size={4} align={'baseline'}>
            <Text color={'#140D0B'} className={styles.title}>
              WE ARE SCRIBEX
              <Col className={styles.dot}>
              </Col>
            </Text>
          </Space>
          <Col>
            <Text color={'#140D0B'} className={styles.description}>
              OUR PURSUIT OF PERFECTION DRIVES OUR EVOLVING SPIRIT, <br/>
              WE FORGE EXCEPTIONAL PARTNERSHIPS, RETURNING
              ABUNDANT REWARDS
            </Text>
          </Col>
        </Space>
      </Col>
      <Col span={24} className={styles.team_card_container}>
        <Row gutter={[1, 1]}>
          {teamMemberData.map((_member) =>
            <Col key={_member.id}
                 xl={{flex: "20%"}}
                 lg={{flex: "25%"}}
                 md={{flex: "33.33%"}}
                 sm={{flex: "50%"}}
                 xs={{flex: "50%"}}
                 className={styles.card_element}
            >
              <PersonalCard key={_member.id} {..._member}/>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  )
})